import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import Program from 'sections/ScreverBtqProgram/Program';
import BoxList from 'sections/ScreverBtqProgram/BoxList';
import CarlySec from 'sections/ScreverBtqProgram/CarlySec';
import FooterCommon from 'sections/ScreverOcxProgram/FooterCommon';

import { PROGRAM } from 'mocks/screver-btq-program/program';

import 'sections/ScreverOcxProgram/common.scss';

const PAGES = [
  {
    title: 'Cinema',
    url: '/events/screver-btq-program/cinema',
  },
  {
    title: 'Hackathons',
    url: '/events/screver-btq-program/vote',
  },
  {
    title: '✌🏻feedback',
    url: '/events/screver-btq-program/feedback',
  },
];

const ProgramPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver BTQ Event | Program"
      >
        <meta name="description" content="" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program scr-btq-event">
        <video
          playsInline
          autoPlay
          muted
          loop
          poster="https://wp.screver.com/wp-content/uploads/2023/05/bg-noise-2.png"
          src="https://wp.screver.com/wp-content/uploads/2023/05/Vawe_Capptoo_Noise.mp4"
          className="screver-btq-program-video"
        ></video>

        <HeaderCommon
          title="Program"
          url="/events/screver-btq-program/"
          styleProp="header-green-title"
        />

        <main className="">
          <div className="ocx-prog-wrap">
            <div className=""></div>
            <Program data={PROGRAM.firstPart} lastNoLine={true} />
            <BoxList />
            {/* <Program data={PROGRAM.secondPart} lastNoLine={true} /> */}
            <CarlySec />
          </div>
        </main>

        <FooterCommon linksToPages={PAGES} />
      </div>
    </>
  );
};

export default ProgramPage;
